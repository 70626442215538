import React from 'react';
import { useParams } from 'react-router-dom';

const ReadingDetails = ({ readings }) => {
  const { id } = useParams();
  const reading = readings.find(r => r._id === id);

  if (!reading) {
    return <div>Reading not found</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center">
      <h1 className="text-3xl font-bold mb-4">Reading Details</h1>
      <div className="bg-white shadow-md rounded-lg p-4">
        <p><strong>Value:</strong> {reading.value}</p>
        <p><strong>Date:</strong> {new Date(reading.date).toLocaleString()}</p>
        <p><strong>Meal:</strong> {reading.meal}</p>
      </div>
    </div>
  );
};

export default ReadingDetails;