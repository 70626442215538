import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';

const ReadingsPage = ({ readings }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const readingsPerPage = 20;

  const filteredReadings = readings.filter((reading) => {
    const readingDate = new Date(reading.date);
    const adjustedEndDate = endDate ? new Date(endDate).setHours(23, 59, 59, 999) : null;
    const matchesDateRange =
      (!startDate || readingDate >= startDate) &&
      (!adjustedEndDate || readingDate <= adjustedEndDate);
    const matchesSearchQuery =
      reading.value.toString().includes(searchQuery) ||
      (reading.meal && reading.meal.toLowerCase().includes(searchQuery.toLowerCase()));
    return matchesDateRange && matchesSearchQuery;
  });

  const indexOfLastReading = currentPage * readingsPerPage;
  const indexOfFirstReading = indexOfLastReading - readingsPerPage;
  const currentReadings = filteredReadings.slice(indexOfFirstReading, indexOfLastReading);

  const totalPages = Math.ceil(filteredReadings.length / readingsPerPage);

  const averageValue = filteredReadings.reduce((sum, reading) => sum + reading.value, 0) / filteredReadings.length || 0;

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center">
      <h1 className="text-3xl font-bold mb-4">All Readings</h1>
      <div className="mb-4 flex space-x-4">
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          placeholderText="Start Date"
          className="border border-gray-300 p-2 rounded"
        />
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          placeholderText="End Date"
          className="border border-gray-300 p-2 rounded"
        />
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search by value or meal"
          className="border border-gray-300 p-2 rounded"
        />
      </div>
      <div className="mb-4">
        <p className="text-lg">Average Blood Sugar Levels: {averageValue.toFixed(2)}</p>
      </div>
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2 text-center">Date</th>
            <th className="py-2 text-center">Sugar Reading</th>
            <th className="py-2 text-center">Meal</th>
          </tr>
        </thead>
        <tbody>
          {currentReadings.map((reading) => (
            <tr key={reading._id}>
              <td className="border px-4 py-2 text-center">{format(new Date(reading.date), 'EEE - dd MMM yyyy - HH:mm')}</td>
              <td className="border px-4 py-2 text-center">{reading.value}</td>
              <td className="border px-4 py-2 text-center">{reading.meal || 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-4 flex space-x-2">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => setCurrentPage(index + 1)}
            className={`px-4 py-2 rounded ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
          >
            {index + 1}
          </button>
        ))}
      </div>
      <div className="mt-4 flex space-x-2">
        <button 
        onClick={() => window.location.href = '/'}
        className='bg-blue-500 text-white p-2 rounded'
        >
          Return to Main Page
        </button>
      </div>
    </div>
  );
};

export default ReadingsPage;