import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './index.css';
import Graph from './Graph';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useAuth } from './auth/AuthProvider';
import { useNavigate } from 'react-router-dom';

const MainApp = () => {
  const [readings, setReadings] = useState([]);
  const [newReading, setNewReading] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [meal, setMeal] = useState('');
  const [editingReading, setEditingReading] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch readings data when the component mounts
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/readings`, { withCredentials: true })
      .then(response => setReadings(response.data))
      .catch(error => console.error(error));
  }, []);

  const addReading = () => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/readings`, { value: newReading, date: selectedDate.toISOString(), meal }, { withCredentials: true })
      .then(response => {
        setReadings([...readings, response.data]);
        setNewReading('');
        setSelectedDate(new Date());
        setMeal('');
        setSuccessMessage('Reading added successfully!');
        setTimeout(() => setSuccessMessage(''), 3000); // Clear message after 3 seconds
      })
      .catch(error => console.error(error));
  };

  const updateReading = () => {
    axios.put(`${process.env.REACT_APP_BACKEND_URL}/readings/${editingReading._id}`, { value: newReading, date: selectedDate.toISOString(), meal }, { withCredentials: true })
      .then(response => {
        setReadings(readings.map(reading => reading._id === editingReading._id ? response.data : reading));
        setEditingReading(null);
        setNewReading('');
        setSelectedDate(new Date());
        setMeal('');
      })
      .catch(error => console.error(error));
  };

  const startEditing = (reading) => {
    setEditingReading(reading);
    setNewReading(reading.value);
    setSelectedDate(new Date(reading.date));
    setMeal(reading.meal);
  };

  const last30DaysReadings = readings.filter(reading => {
    const readingDate = new Date(reading.date);
    const today = new Date();
    const thirtyDaysAgo = new Date(today.setDate(today.getDate() - 30));
    return readingDate >= thirtyDaysAgo;
  });

  const handlePointClick = (readingId) => {
    navigate(`/readings/${readingId}`);
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4">
      <h1 className="text-3xl font-bold mb-4">Blood Sugar Tracker</h1>
      <div className="mb-4 w-full max-w-md">
        {successMessage && (
          <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4">
            {successMessage}
          </div>
        )}
        <div className="mb-4">
          <label className="block text-gray-700 mb-2" htmlFor="newReading">New Reading</label>
          <input
            id="newReading"
            type="number"
            value={newReading}
            onChange={(e) => setNewReading(e.target.value)}
            className="border border-gray-300 p-2 rounded w-half max-w-xs"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2" htmlFor="selectedDate">Date</label>
          <DatePicker
            id="selectedDate"
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            showTimeSelect
            dateFormat="Pp"
            className="border border-gray-300 p-2 rounded w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2" htmlFor="meal">Meal</label>
          <input
            id="meal"
            type="text"
            value={meal}
            onChange={(e) => setMeal(e.target.value)}
            placeholder="Meal"
            className="border border-gray-300 p-2 rounded w-full"
          />
        </div>
        {editingReading ? (
          <button
            onClick={updateReading}
            className="bg-green-500 text-white p-2 rounded w-full"
          >
            Update Reading
          </button>
        ) : (
          <button
            onClick={addReading}
            className="bg-blue-500 text-white p-2 rounded w-full"
          >
            Add Reading
          </button>
        )}
      </div>
      <div className="w-full max-w-4xl overflow-x-auto">
        <Graph readings={last30DaysReadings} onPointClick={handlePointClick} />
      </div>
      <button
        onClick={() => navigate('/readings')}
        className="bg-blue-500 text-white p-2 rounded mt-4"
      >
        View All Readings
      </button>
      <br />
      <br />
      <button
        onClick={logout}
        className="bg-red-500 text-white p-2 rounded mb-4"
      >
        Logout
      </button>
    </div>
  );
};

export default MainApp;