import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import 'chartjs-adapter-date-fns';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale);

const Graph = ({ readings, onPointClick }) => {
  const data = {
    labels: readings.map(reading => new Date(reading.date)),
    datasets: [
      {
        label: 'Blood Sugar Level',
        data: readings.map(reading => ({ x: new Date(reading.date), y: reading.value, id: reading._id })),
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Blood Sugar Readings (Last 30 Days)',
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
        },
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const { index } = elements[0];
        const readingId = data.datasets[0].data[index].id;
        onPointClick(readingId);
      }
    },
  };

  return <Line data={data} options={options} />;
};

export default Graph;