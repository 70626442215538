import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './auth/AuthProvider';
import Login from './Login';
import MainApp from './MainApp';
import ReadingDetails from './ReadingDetails';
import ReadingsPage from './ReadingsPage';
import axios from 'axios';

const ProtectedRoute = ({ element }) => {
    const { account } = useAuth();
    console.log('ProtectedRoute account:', account); // Add this line for debugging
    return account ? element : <Navigate to="/login" />;
  };

const App = () => {
  const [readings, setReadings] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/readings`, { withCredentials: true })
      .then(response => setReadings(response.data))
      .catch(error => console.error(error));
  }, []);

  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<ProtectedRoute element={<MainApp readings={readings} />} />} />
          <Route path="/readings" element={<ProtectedRoute element={<ReadingsPage readings={readings} />} />} />
          <Route path="/readings/:id" element={<ProtectedRoute element={<ReadingDetails readings={readings} />} />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;