import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './auth/AuthProvider';

const Login = () => {
  const { login, account } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (account) {
      navigate('/');
    }
  }, [account, navigate]);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center">
      <img src='/logo512.png' alt='logo' />
      <h1 className="text-3xl font-bold mb-4">Blood Sugar Tracker</h1>
      <button
        onClick={login}
        className="bg-blue-500 text-white p-2 rounded"
      >
        Login with Microsoft
      </button>
    </div>
  );
};

export default Login;